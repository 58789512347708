<template>
    <div id="heading_wrapper">
        <hr id="before" />
        <h3>{{text}}</h3>
        <hr id="after" />
    </div>
</template>

<script>
    export default {
        name: 'ContentHeading',
        props: ['text'],
    }
</script>

<style lang="scss" scoped>
    #heading_wrapper{
        display: flex;
        h3{
            font-size: 1rem !important;
            font-weight: 500;
            white-space: nowrap;
            margin-right:5px;
            margin-left:5px;
            text-transform: uppercase;
        }
        hr#after{
            width: 95%;
            margin: auto;
            border: 0;
            height: 2px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 80%, #ffff);
            
        }
        hr#before{
            width: 5%;
            margin: auto;
            border: 0;
            height: 2px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5) 80%, #ffff);
            
        }
    }
</style>